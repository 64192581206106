import React from "react"
import { logUserEvent } from "../common/firebase.utils"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { ReactComponent as BadgeIcon } from "../images/badge-l-w.svg"
import { showPaymentPopup } from "../store/slices/layerSlice"

type MembershipBarProps = {
  userId: string | null
  membershipFrom?: string | null
  className?: string
}

export default function MembershipBar({
  userId,
  membershipFrom,
  className = ""
}: MembershipBarProps) {
  const isMembership = membershipFrom != null
  const dispatch = useDispatch()

  const onClickJoin = () => {
    if (isMembership) {
      return
    }

    if (!userId) {
      alert("로그인이 필요합니다.")
      return
    }

    logUserEvent("click_join_on_mypage")
    dispatch(showPaymentPopup())
  }

  return (
    <Container
      className={`font-medium text-white flex items-center ${
        isMembership ? "py-2 px-3 rounded-full" : "p-3"
      }  ${className}`}
    >
      <BadgeIcon className="mr-1" />
      {isMembership ? (
        <p className="font-bold text-xs flex-1">뭐라고할까 멤버십</p>
      ) : (
        <p className="text-left text-[13px] flex-1">
          <b>5,900원</b>, 1회 결제로 무제한 이용!
        </p>
      )}
      <span
        className={`${
          isMembership
            ? "text-[10px]"
            : "text-xs underline cursor-pointer hover:font-bold"
        }`}
        onClick={onClickJoin}
      >
        {isMembership ? `${membershipFrom} 가입` : "멤버십 가입"}
      </span>
    </Container>
  )
}

const Container = styled.div`
  background: linear-gradient(
    290deg,
    #7869d6 -15.93%,
    #3d3764 14.76%,
    #16122c 45.45%
  );
`
