import React from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { logUserEvent } from "../common/firebase.utils"
import { requestPay } from "../common/utils"
import { useUserInfo } from "../hooks/useUserInfo"
import { ReactComponent as CloseButton } from "../images/close-gray.svg"
import { showLoginPopup } from "../store/slices/layerSlice"
import useIsMobile from "../hooks/useIsMobile"
import { ReactComponent as ArrowPc } from "../images/pay-arrow-pc.svg"
import { ReactComponent as ArrowMobile } from "../images/pay-arrow-m.svg"
import { ReactComponent as RadioIcon } from "../images/formkit_radio.svg"

function PaymentPopup({ onCloseClick }) {
  const { userId, loggedIn } = useUserInfo()
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const onClickPayNow = () => {
    logUserEvent("click_pay_now")
    if (loggedIn) {
      requestPay(userId)
    } else {
      dispatch(
        showLoginPopup({
          message: "로그인 후 결제를 진행할 수 있습니다."
        })
      )
    }
    onCloseClick()
  }

  return (
    <Background>
      <Container>
        <HeaderContainer className="flex-col h-[110px] md:h-[110px] text-white flex pt-10 md:pt-0 items-center text-center md:justify-center gap-2">
          <p className="leading-none font-medium">
            {isMobile
              ? "한 번의 결제로 무제한 사용"
              : "계속 업데이트되는 만개 이상의 실생활 템플릿"}
          </p>
          <p className="font-medium leading-tight text-sm">
            {isMobile
              ? "계속 업데이트되는 만개 이상의 실생활 템플릿"
              : "한 번의 결제로 무제한 사용하세요"}
          </p>
        </HeaderContainer>
        <CloseButton id="close_button" onClick={onCloseClick} />
        <div className="relative py-8 px-6 md:px-10 md:space-y-4 md:block flex flex-col h-[250px] md:h-auto">
          <p className="text-xl font-bold text-primary leading-none">
            뭐라고할까 멤버십
          </p>
          <p className="pl-4 leading-none flex-1 mt-4 md:mt-0">
            <span className="font-semibold">결제수단</span>
            <span className="ml-4 font-medium">
              <RadioIcon className="inline-block mr-2" />
              신용카드
            </span>
          </p>
          <div className="h-[43px] px-4 bg-white rounded-lg border border-[#ebebeb] flex items-center">
            <span className="font-semibold">결제 금액</span>
            <span className="text-xs ml-2 flex-1">(부가세 포함)</span>
            <span className="font-semibold">5,900 원</span>
          </div>
          <div className="!mt-0 absolute md:top-[34px] top-[40px] right-[6.62px] md:right-auto md:left-[224px] flex flex-col md:flex-row md:gap-x-[8.94px]">
            {isMobile ? (
              <ArrowMobile />
            ) : (
              <ArrowPc className="mt-[2.64px]" width={64} />
            )}
            <p className="ml-[12.38px] text-[#9593a5] text-xs font-normal font-hanna w-[116px] md:w-auto break-keep mt-[6.68px] md:mt-0">
              {isMobile
                ? "기능 업데이트 등으로 가격이 인상될 수 있어요."
                : "기능 업데이트 등으로 향후 가격이 인상될 수 있어요."}
              <br />
              지금 얼리버드로 가입하세요!
            </p>
          </div>
        </div>
        <Buttons>
          <div onClick={onClickPayNow}>결제하기</div>
        </Buttons>
      </Container>
    </Background>
  )
}

const Container = styled.div`
  position: relative;
  background-color: #f4f5f9;
  width: 562px;
  height: 371px;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  overflow: hidden;
  #close_button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
    fill: #666666;
    cursor: pointer;
  }
  @media (max-width: 800px) {
    width: calc(100% - 32px);
    height: 451px;
  }
`
const Buttons = styled.div`
  width: 327px;
  margin: 8px auto 0;
  > * {
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
  }
  > :first-child {
    color: #ffffff;
    border-radius: 48px;
    background: #5551ff;
    line-height: 3rem;
    font-size: 14px;
  }
  @media (max-width: 800px) {
    width: calc(100% - 48px);
  }
`

const HeaderContainer = styled.div`
  background-image: linear-gradient(
    290.11deg,
    #7869d6 -15.93%,
    #3d3764 14.76%,
    #16122c 45.45%
  );
`

const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
`

export default PaymentPopup
