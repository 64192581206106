import React from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { logUserEvent } from "../common/firebase.utils"
import { useUserInfo } from "../hooks/useUserInfo"
import { ReactComponent as CloseButton } from "../images/close-gray.svg"
import { ReactComponent as CardsImg } from "../images/lock-cards.svg"
import { showLoginPopup, showPaymentPopup } from "../store/slices/layerSlice"

function UnlockPopup({ onCloseClick }) {
  const { loggedIn } = useUserInfo()
  const history = useHistory()
  const dispatch = useDispatch()

  const onClickJoinMembership = () => {
    logUserEvent("click_join_on_unlock_popup")
    if (loggedIn) {
      dispatch(showPaymentPopup())
    } else {
      dispatch(
        showLoginPopup({
          message: "환영합니다!\n간편하게 로그인하고 멤버십을 가입하세요."
        })
      )
    }
    onCloseClick()
  }

  const onClickRegisterNow = () => {
    logUserEvent("click_write_on_unlock_popup")
    if (loggedIn) {
      history.push("/write")
    } else {
      dispatch(
        showLoginPopup({
          message: "환영합니다!\n간편하게 로그인하고 템플릿을 등록하세요.",
          redirect: "/write"
        })
      )
    }
    onCloseClick()
  }

  return (
    <Background>
      <Container>
        <Gradient />
        <CloseButton id="close_button" onClick={onCloseClick} />
        <Content>
          <CardsImg className="mx-auto" />
          <Texts>
            <p>템플릿 이용 안내</p>
            <p>
              템플릿을 공유해 주시면 이후 30일 간<br />
              모든 템플릿을 자유롭게 조회 및 복사 하실 수 있어요!
            </p>
          </Texts>
          <Buttons>
            <div onClick={onClickRegisterNow}>네 지금 등록할게요</div>
            <div onClick={onCloseClick}>다음에 할게요</div>
          </Buttons>
        </Content>
        <BottomContainer className="md:flex-row flex-col text-white flex items-center gap-5 md:gap-0 md:justify-between absolute bottom-0 inset-x-0 py-10 md:py-[30px] pr-8 pl-6">
          <p className="md:text-left text-sm leading-[22px] font-medium">
            템플릿 등록하기 귀찮다면,
            <br />
            <b className="text-base leading-none font-bold">5,900원</b> 한 번의
            결제로 평생 무제한 이용하세요
          </p>
          <div
            id="join-button"
            className="bg-[rgba(85,81,255,0.10)] rounded-[4px] text-sm font-semibold leading-[48px] px-10 md:px-6 cursor-pointer"
            onClick={onClickJoinMembership}
          >
            멤버십 가입하기
          </div>
        </BottomContainer>
      </Container>
    </Background>
  )
}

const Container = styled.div`
  position: relative;
  width: 569px;
  height: 509px;
  background: #f9f9f9;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  #close_button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
    fill: #666666;
    cursor: pointer;
  }
  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    #close_button {
      opacity: 0.35;
    }
  }
`
const Content = styled.div`
  position: absolute;
  top: 28.37px;
  left: 0;
  right: 0;
  color: #333333;
  text-align: center;
  @media (max-width: 800px) {
    top: 20%;
  }
`
const Gradient = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 337px;
  background: linear-gradient(
    180deg,
    #e2e1fb 0%,
    rgba(255, 255, 255, 0) 66.02%
  );
  @media (max-width: 800px) {
    height: 100%;
  }
`
const Texts = styled.div`
  margin-top: -3.625px;
  text-align: center;
  > :first-child {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
  }
  > :last-child {
    margin-top: 0.5rem;
    line-height: 24px;
  }
  @media (max-width: 800px) {
    margin-top: 21px;
    > :first-child {
      font-size: 18px;
      line-height: 18px;
    }
    > :last-child {
      font-size: 14px;
    }
  }
`
const Buttons = styled.div`
  width: 327px;
  margin: 29.26px auto 0;
  > * {
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
  > :first-child {
    color: #ffffff;
    border: 1px solid #5551ff;
    border-radius: 48px;
    background: #5551ff;
    line-height: 3rem;
  }
  > :last-child {
    margin-top: 16px;
    color: #333333;
  }
  @media (max-width: 800px) {
    position: fixed;
    bottom: 57px;
    width: calc(100% - 48px);
    margin-left: 24px;
  }
`

const BottomContainer = styled.div`
  background-image: linear-gradient(
    290.11deg,
    #7869d6 -15.93%,
    #3d3764 14.76%,
    #16122c 45.45%
  );

  #join-button {
    --border-angle: 0turn; // For animation.
    --main-bg: conic-gradient(
      from var(--border-angle),
      #2a2880,
      #2a2880 5%,
      #2a2880 60%,
      #2a2880 95%
    );

    border: solid 1px #5551ff;
    border-radius: 4px;

    background-position: center center;

    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }

    &:hover {
      border: 1px solid transparent;
      --gradient-border: conic-gradient(
        from var(--border-angle),
        #5551ff 25%,
        #88ff00,
        #5551ff 99%,
        #5551ff
      );
      background: 
    // padding-box clip this background in to the overall element except the border.
        var(--main-bg) padding-box,
        // border-box extends this background to the border space
        var(--gradient-border) border-box,
        // Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background.
        var(--main-bg) border-box;

      animation: bg-spin 2.5s linear infinite;
    }

    @property --border-angle {
      syntax: "<angle>";
      inherits: true;
      initial-value: 0turn;
    }
  }
`

const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
`

export default UnlockPopup
